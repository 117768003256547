export const API_DEV_URL = "https://api-dev.pulsysurvey.com";
export const API_URL = "https://api.pulsysurvey.com";

export const QUESTION_TYPES = {
  LIKERT_SCALE: "LIKERT_SCALE",
  NPS_SCALE: "NPS_SCALE",
  SELECT: "SELECT",
  OPEN_ENDED: "OPEN_ENDED",
};

export const THREAD_TYPE = {
  QUESTION: "QUESTION",
  ANSWER: "ANSWER",
};
export const DISPLAY_RESPONSES = {
  PER_QUESTION: "PER_QUESTION",
  PER_USER: "PER_USER",
};
export const KPI_TYPE = {
  AVERAGE: "AVERAGE",
  MEDIAN: "MEDIAN",
  NPS: "NPS",
};

export const SORTING_OPTIONS = {
  MOST_RECENT: {
    value: "MOST_RECENT",
    label: "Most recent",
    sortFunction: (a, b) => {
      if (a.i > b.i) {
        return -1;
      }
      if (a.i < b.i) {
        return 1;
      }
      return 0;
    },
  },
  OLDEST_FIRST: {
    value: "OLDEST_FIRST",
    label: "Oldest first",
    sortFunction: (a, b) => {
      if (a.i > b.i) {
        return 1;
      }
      if (a.i < b.i) {
        return -1;
      }
      return 0;
    },
  },
  LOWEST_RATES: {
    value: "LOWEST_RATES",
    label: "Lowest rates",
    sortFunction: (a, b) => {
      if (a.content > b.content) {
        return 1;
      }
      if (a.content < b.content) {
        return -1;
      }
      return 0;
    },
  },
  BEST_RATES: {
    value: "BEST_RATES",
    label: "Best rates",
    sortFunction: (a, b) => {
      if (a.content > b.content) {
        return -1;
      }
      if (a.content < b.content) {
        return 1;
      }
      return 0;
    },
  },
};

// 'DAILY', 'DAILY_WEEKDAYS', 'WEEKLY', 'BIWEEKLY', 'TRIWEEKLY', 'MONTHLY', 'BIMONTHLY', 'QUARTERLY'
export const FREQUENCY = {
  DAILY_WEEKDAYS: "DAILY_WEEKDAYS",
  DAILY: "DAILY",
  WEEKLY: "WEEKLY",
  BIWEEKLY: "BIWEEKLY",
  TRIWEEKLY: "TRIWEEKLY",
  MONTHLY: "MONTHLY",
  BIMONTHLY: "BIMONTHLY",
  QUARTERLY: "QUARTERLY",
};
