import { ClockCircleFilled, PlayCircleFilled } from "@ant-design/icons";
import { Tag, Tooltip } from "antd";
import moment from "moment";
import React from "react";
import styles from "styles/components/surveyNameStatus.module.scss";
import { getTextFromFrequency } from "utils/utils";

const SurveyNameStatus = ({ survey }) => {
  const status =
    survey.deadline && moment(survey.deadline).isBefore(moment())
      ? "CLOSED"
      : survey.status;
  return (
    <div>
      <div className={styles.itemName}>{survey.name}</div>
      <div>
        <div className={styles.itemStatus}>
          <Tag
            color={status === "LIVE" ? "green" : "volcano"}
            className={styles.itemStatusInner}
          >
            <div className={`${styles.circle} ${styles[status]}`}></div>
            {status}
          </Tag>
          {survey.anonymous && (
            <Tooltip title="Anonymous survey">
              <Tag color="purple" className={styles.anonymous}>
                🥷&nbsp;Anonymous
              </Tag>
            </Tooltip>
          )}
          {survey.isRecurring && (
            <Tooltip title="Recurring Survey">
              <Tag color="blue" className={styles.frequency}>
                🔄&nbsp;{getTextFromFrequency(survey.frequency)}
              </Tag>
            </Tooltip>
          )}
        </div>
        <div className={styles.itemDates}>
          {survey.publishedAt && (
            <div>
              <Tooltip title="Published At" placement={["bottom"]}>
                <PlayCircleFilled /> {moment(survey.publishedAt).format("L")}
              </Tooltip>
            </div>
          )}
          {survey.deadline && (
            <div className={styles.deadline}>
              <Tooltip title="Deadline" placement={["bottom"]}>
                <ClockCircleFilled /> {moment(survey.deadline).format("L")}
              </Tooltip>
            </div>
          )}
        </div>
      </div>
    </div>
  );
};

export default SurveyNameStatus;
