import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Avatar, Button, Modal } from "antd";
import moment from "moment";
import React from "react";
import styles from "styles/components/userListModal.module.scss";

const AvatarWithStatus = ({ user: u }) => (
  <div className={styles.avatarWrapper}>
    <Avatar size={48} src={u.profilePicture} className={styles.avatar} />
    <div className={styles.avatarStatus}>
      {u.incomplete ? (
        <ExclamationCircleFilled
          style={{ color: "orange" }}
          className={styles.statusIcon}
        />
      ) : (
        <></>
      )}
    </div>
  </div>
);

const UserListModal = ({ users, isOpen = false, close = () => {} }) => {
  const allUsersSorted = users.sort((a, b) => {
    const adate = moment(a.submittedAt);
    const bdate = moment(b.submittedAt);
    if (adate.isBefore(bdate)) {
      return 1;
    }
    if (bdate.isBefore(adate)) {
      return -1;
    }
    return 0;
  });

  const incomplete = allUsersSorted.filter((e) => e.incomplete === true);
  const full = allUsersSorted.filter((e) => e.incomplete === false);
  return (
    <Modal
      onCancel={close}
      open={isOpen}
      footer={[
        <Button
          key={"closeUserList"}
          icon={<CloseOutlined />}
          type="primary"
          block
          onClick={close}
        >
          Close
        </Button>,
      ]}
      title={`Participants (${full.length} submited${
        incomplete.length > 0 ? ` and ${incomplete?.length} incomplete` : ""
      })`}
    >
      {incomplete && incomplete.length > 0 && (
        <div style={{ marginBottom: 34, marginTop: 18 }}>
          <h3 className={styles.title}>
            <ExclamationCircleFilled
              className={styles.iconTitle}
              style={{ color: "orange" }}
            />
            &nbsp;Incomplete responses ({incomplete.length})
          </h3>
          <div>These responses were not submited by participants</div>
          <ul className={styles.list}>
            {incomplete.map((u) => (
              <li className={styles.userElement} key={u.name}>
                <AvatarWithStatus user={u} />
                <div className={styles.nameDateWrapper}>
                  <div className={styles.username}>{u.name}</div>
                  <div className={styles.submitted}>
                    {`Last answered on ${moment(u.lastAnsweredAt).format(
                      "L - HH:mm"
                    )}`}
                  </div>
                </div>
              </li>
            ))}
          </ul>
        </div>
      )}

      <h3 className={styles.title}>Complete responses ({full.length})</h3>
      <ul className={styles.list}>
        {full.map((u) => (
          <li className={styles.userElement} key={u.name}>
            <AvatarWithStatus user={u} />
            <div className={styles.nameDateWrapper}>
              <div className={styles.username}>{u.name}</div>
              <div className={styles.submitted}>
                {`Submitted on ${moment(u.submittedAt).format("L - HH:mm")}`}
              </div>
            </div>
          </li>
        ))}
      </ul>
    </Modal>
  );
};

export default UserListModal;
