const Logo = () => (
  <div>
    <a href="/">
      <img
        style={{ width: "38px" }}
        src="/img/logo/logo.png"
        alt="pulsy logo"
      />
    </a>
  </div>
);

export default Logo;
