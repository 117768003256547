import { HomeOutlined } from "@ant-design/icons";
import { Breadcrumb, Button } from "antd";
import Header from "components/header";
import Loading from "components/loading";
import SurveyNameStatus from "components/surveyNameStatus";
import SurveyParticipation from "components/surveyParticipation";
import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import styles from "styles/pages/surveys.module.scss";
import { listSurveys } from "utils/api";
const { default: PageWrapper } = require("components/pageWrapper");

const SurveyItem = ({ survey }) => (
  <div key={survey._id} className={styles.itemWrapper}>
    <div className={styles.leftWrapper}>
      <SurveyNameStatus survey={survey} />
    </div>
    <div className={styles.itemParticipation}>
      <SurveyParticipation survey={survey} />
    </div>
    <div className={styles.itemCTA}>
      <Link to={`/surveys/${survey._id}`}>
        <Button type="primary">View responses</Button>
      </Link>
    </div>
  </div>
);

const Surveys = () => {
  const [loading, setLoading] = useState(true);
  const [surveys, setSurveys] = useState(null);

  const list = () => {
    setLoading(true);
    listSurveys()
      .then((data) => {
        setSurveys(data);
      })
      .catch((e) => {
        console.error("Error loading surveys", e);
      })
      .finally(() => {
        setLoading(false);
      });
  };

  useEffect(() => {
    list();
  }, []);
  return (
    <>
      <Header />
      <PageWrapper width={880}>
        <Breadcrumb
          className={styles.bread}
          items={[
            {
              title: (
                <a href="/">
                  <HomeOutlined />
                </a>
              ),
            },
            {
              title: <span>Surveys</span>,
            },
          ]}
        />
        <h1 className={styles.title}>{`Surveys${
          surveys ? ` (${surveys.length})` : ""
        }`}</h1>
        <div className={styles.subtitle}>
          Find live or finished surveys. Drafts are only visible in Slack.
        </div>
        {loading && <Loading />}
        {!loading && (
          <div>
            {(surveys || []).map((sur) => (
              <SurveyItem key={sur._id} survey={sur} />
            ))}
          </div>
        )}
      </PageWrapper>
    </>
  );
};

export default Surveys;
