/* eslint-disable react-hooks/exhaustive-deps */
import { SendOutlined } from "@ant-design/icons";
import { Popover, Tag } from "antd";
import React, { useEffect, useState } from "react";
import styles from "styles/components/surveyParticipation.module.scss";
import Circle from "./circle";
import { Cell, PieChart, Pie } from "recharts";

const PARTICIPATION_COLORS = {
  blue: "#1252dd9c",
  green: "#4edd129c",
  red: "#e60f0f9c",
  gold: "gold",
};

const getColorFromRate = (rate) => {
  if (rate < 0.3) {
    return PARTICIPATION_COLORS.red;
  }
  if (rate < 0.5) {
    return PARTICIPATION_COLORS.gold;
  }
  if (rate < 0.7) {
    return PARTICIPATION_COLORS.blue;
  }
  return PARTICIPATION_COLORS.green;
};

const SurveyParticipation = ({ survey, showIncomplete = false }) => {
  const [data, setData] = useState([]);
  const [responseCount, setResponseCount] = useState(null);

  const computeData = () => {
    let responseCount = survey.responsesCount;
    if (showIncomplete) {
      responseCount += survey.incompleteResponsesCount;
    }
    setResponseCount(responseCount);
    setData([
      {
        name: "Participants",
        value: responseCount,
        color: getColorFromRate(responseCount / survey.usersCount),
      },
      {
        name: "Missing",
        value: survey.usersCount - responseCount,
        color: "grey",
      },
    ]);
  };

  useEffect(() => {
    computeData();
  }, [survey, showIncomplete]);

  return (
    <>
      <div className={styles.wrapper}>
        <div className={styles.charts}>
          <PieChart width={80} height={60} key={`rc_${responseCount}`}>
            <Pie
              key={`pie_${responseCount}`}
              data={data}
              cx={24}
              cy={24}
              innerRadius={18}
              outerRadius={26}
              fill="#8884d8"
              paddingAngle={0}
              dataKey="value"
            >
              {data.map((entry, index) => (
                <Cell
                  key={`cell-${index}-${responseCount}`}
                  fill={entry.color}
                />
              ))}
            </Pie>
          </PieChart>
        </div>
        <div className={styles.participationContent}>
          <div className={styles.itemHeader}>
            <SendOutlined /> Participation
          </div>
          <div className={styles.itemParticipationInner}>
            <div className={styles.percentageParticipationWrapper}>
              <Popover
                content={
                  <div>
                    <ul className={styles.listColor}>
                      <li>
                        <Tag color={"green"}>
                          <Circle color={PARTICIPATION_COLORS.green} /> Amazing
                        </Tag>{" "}
                        {"participation > 70%"}
                      </li>
                      <li>
                        <Tag color={"blue"}>
                          <Circle color={PARTICIPATION_COLORS.blue} /> Great
                        </Tag>{" "}
                        {"participation > 50%"}
                      </li>
                      <li>
                        <Tag color={"gold"}>
                          <Circle color={PARTICIPATION_COLORS.gold} /> Good
                        </Tag>{" "}
                        {"participation > 30%"}
                      </li>
                      <li>
                        <Tag color={"red"}>
                          <Circle color={PARTICIPATION_COLORS.red} /> Low
                        </Tag>{" "}
                        {"participation under 30%"}
                      </li>
                    </ul>
                  </div>
                }
                title="What does the color mean?"
              >
                <div
                  className={styles.percentageParticipation}
                  style={{
                    color: getColorFromRate(responseCount / survey.usersCount),
                  }}
                >{`${((responseCount / survey.usersCount) * 100).toFixed(
                  0
                )}%`}</div>
              </Popover>
            </div>
            <div className={styles.numberPeople}>
              <b>{`${survey.responsesCount}${
                showIncomplete ? `+${survey.incompleteResponsesCount}` : ""
              }`}</b>
              /{survey.usersCount}
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default SurveyParticipation;
