import styles from "styles/components/header.module.scss";
import Profile from "./profile";
import { Button } from "antd";
import { useNavigate } from "react-router-dom";
import MobileMenu from "./mobileMenu";
import { useWindowSize } from "utils/utils";
import { LogoutOutlined } from "@ant-design/icons";
import Logo from "./logo";

const Header = () => {
  // const location = useLocation();
  const size = useWindowSize();
  const mobile = size.width < 850;

  // const current = location.pathname.split("/")[1];
  const navigate = useNavigate();

  const logout = () => {
    navigate("/logout");
  };
  return (
    <header className={styles.header}>
      <div className={styles.inner}>
        <div className={styles.left}>
          <Logo />
          {/* {!mobile && (
            <Menu
              className={styles.menu}
              selectedKeys={current}
              mode="horizontal"
              items={[
                {
                  label: (
                    <a href="/#/">
                      <GroupOutlined /> Surveys
                    </a>
                  ),
                  key: "surveys",
                },
              ]}
            />
          )} */}
        </div>
        <div className={styles.right}>
          <Profile />
          {!mobile && (
            <Button
              onClick={logout}
              type="link"
              icon={<LogoutOutlined />}
              style={{ color: "#1f3a45" }}
            >
              Logout
            </Button>
          )}
        </div>
        {mobile && <MobileMenu logout={logout} />}
      </div>
    </header>
  );
};

export default Header;
