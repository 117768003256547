/* eslint-disable react-hooks/exhaustive-deps */
import { Avatar, Collapse, Divider, Typography } from "antd";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import styles from "styles/components/perUserResponseDisplay.module.scss";
import { QUESTION_TYPES } from "utils/constants";
import AnswerContent from "./answerContent";
import { ExclamationCircleFilled } from "@ant-design/icons";
import moment from "moment";
import Clarifications from "./clarifications";
import { smoothScroll } from "utils/utils";
const { Text } = Typography;

const Responses = ({ survey, users, refreshAnswers, defaultOpen }) => (
  <Collapse
    className={styles.collapse}
    defaultActiveKey={defaultOpen}
    items={survey.responses.map((response, index) => ({
      key: `A_${response._id}`,
      label: (
        <div className={styles.labelWrapper} id={`response_${response._id}`}>
          <div className={styles.labelLeft}>
            {survey.anonymous ? (
              <b>
                {`🥷 Anonymous ${survey.responses.length - index}`}{" "}
                {response.incomplete ? (
                  <ExclamationCircleFilled
                    style={{ color: "orange", marginLeft: 4 }}
                    className={styles.statusIcon}
                  />
                ) : (
                  <></>
                )}
              </b>
            ) : response.userId ? (
              <div className={styles.userElement}>
                <div className={styles.avatarWrapper}>
                  <Avatar
                    size={28}
                    src={users[response.userId].profilePicture}
                    className={styles.avatar}
                  />
                  <div className={styles.avatarStatus}>
                    {users[response.userId].incomplete ? (
                      <ExclamationCircleFilled
                        style={{ color: "orange" }}
                        className={styles.statusIcon}
                      />
                    ) : (
                      <></>
                    )}
                  </div>
                </div>
                <div className={styles.usernameWrapper}>
                  <div className={styles.username}>
                    {users[response.userId].name}
                  </div>
                </div>
              </div>
            ) : (
              `👤 User ${index + 1}`
            )}
          </div>
          <div className={styles.labelRIght}>
            <div className={styles.date}>
              {response.incomplete
                ? `Last answered at ${moment(response.lastAnsweredAt).format(
                    "L - HH:mm"
                  )}`
                : `Submited on ${moment(response.submittedAt).format(
                    "L - HH:mm"
                  )}`}
            </div>
          </div>
        </div>
      ),
      children: (
        <div>
          {survey.themes.map((theme, indexTheme) => {
            const isLastTheme = indexTheme === survey.themes.length - 1;
            return (
              <div key={theme.id} className={styles.themeWrapper}>
                <div className={styles.themeTitle}>
                  <span>
                    {theme.name}
                    <span className={styles.questionNumberInTitle}>{` - ${
                      theme.questions.length
                    } ${pluralize("question", theme.questions.length)}`}</span>
                  </span>
                </div>
                {theme.questions.map((q, i) => {
                  const responseToQuestion = response.answers.find(
                    (a) => a.questionId === q.id
                  );
                  return (
                    <div key={q.id} className={styles.questionWrapper}>
                      <div className={styles.questionTitle}>
                        <Text mark style={{ marginRight: "8px" }}>
                          <b>
                            {` Q${i + 1} `}{" "}
                            {q.type === QUESTION_TYPES.OPEN_ENDED &&
                              " - Open ended"}
                          </b>
                        </Text>
                        {"  "}
                        <b>{q.text}</b>
                      </div>
                      {responseToQuestion ? (
                        <AnswerContent
                          answer={responseToQuestion}
                          question={q}
                        />
                      ) : (
                        <div className={styles.noResponse}>No response yet</div>
                      )}
                      {response ? (
                        <Clarifications
                          answer={{
                            ...responseToQuestion,
                            responseId: response._id,
                          }}
                          refreshAnswers={refreshAnswers}
                          question={q}
                          incomplete={response.incomplete}
                          users={users}
                          isAnonymous={survey.anonymous}
                        />
                      ) : (
                        <></>
                      )}
                    </div>
                  );
                })}
                {!isLastTheme && <Divider />}
              </div>
            );
          })}
        </div>
      ),
    }))}
  />
);

const PerUserResponseDisplay = ({
  survey,
  users,
  refreshAnswers,
  newResponseInit,
}) => {
  const incompleteResponses = survey.responses.filter((r) => r.incomplete);
  const completeResponses = survey.responses.filter((r) => !r.incomplete);

  const [defaultOpen, setDefaultOpen] = useState(null);

  useEffect(() => {
    if (newResponseInit && newResponseInit.length > 0) {
      setDefaultOpen(`A_${newResponseInit}`);
      setTimeout(() => {
        smoothScroll(`#response_${newResponseInit}`);
      }, 500);
    }
  });

  return (
    <div>
      {survey?.responses &&
      survey?.responses.length > 0 &&
      (newResponseInit ? !!defaultOpen : true) ? (
        <div>
          {incompleteResponses && incompleteResponses.length > 0 ? (
            <div>
              <h3 className={styles.completeTitle}>
                <ExclamationCircleFilled
                  className={styles.iconTitle}
                  style={{ color: "orange" }}
                />
                &nbsp;Incomplete Responses {`(${incompleteResponses.length})`}
              </h3>
              <Responses
                survey={{
                  ...survey,
                  responses: incompleteResponses,
                }}
                users={users}
                refreshAnswers={refreshAnswers}
              />
              <h3 className={styles.completeTitle}>
                Complete Responses {`(${completeResponses.length})`}
              </h3>
              <Responses
                survey={{
                  ...survey,
                  responses: completeResponses,
                }}
                users={users}
                refreshAnswers={refreshAnswers}
              />
            </div>
          ) : (
            <Responses
              survey={{
                ...survey,
                responses: completeResponses,
              }}
              users={users}
              refreshAnswers={refreshAnswers}
              defaultOpen={defaultOpen}
            />
          )}
        </div>
      ) : (
        <div>
          <Divider />
          No responses yet.
        </div>
      )}
    </div>
  );
};

export default PerUserResponseDisplay;
