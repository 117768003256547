import { Rate } from "antd";
import { QUESTION_TYPES } from "utils/constants";
import styles from "styles/components/questionAnswersModal.module.scss";

const AnswerContent = ({ answer, question }) => (
  <div className={styles.answerContentInner}>
    {[QUESTION_TYPES.LIKERT_SCALE, QUESTION_TYPES.NPS_SCALE].includes(
      question.type
    ) &&
      answer.content && (
        <div style={{ display: "flex", alignItems: "center" }}>
          <Rate
            disabled
            defaultValue={answer.content}
            count={question.type === QUESTION_TYPES.LIKERT_SCALE ? 5 : 10}
          />
          {question.type === QUESTION_TYPES.NPS_SCALE && (
            <span style={{ marginLeft: 8 }}>{`(${answer.content})`}</span>
          )}
        </div>
      )}
    {question.type === QUESTION_TYPES.SELECT && (
      <div>{question.multi ? answer.content.join(" • ") : answer.content}</div>
    )}
    {answer.comment && answer.comment.length > 0 && (
      <div
        className={styles.comment}
        dangerouslySetInnerHTML={{
          __html: "« " + answer.comment.replace(/\n/g, "<br />") + " »",
        }}
      />
    )}
    {question.type === QUESTION_TYPES.OPEN_ENDED && (
      <div
        className={styles.comment}
        dangerouslySetInnerHTML={{
          __html: "« " + (answer.content || "").replace(/\n/g, "<br />") + " »",
        }}
      />
    )}
  </div>
);

export default AnswerContent;
