/* eslint-disable react-hooks/exhaustive-deps */
import { CloseOutlined, ExclamationCircleFilled } from "@ant-design/icons";
import { Avatar, Button, Checkbox, Divider, Modal, Select, Tag } from "antd";
import moment from "moment";
import pluralize from "pluralize";
import React, { useEffect, useState } from "react";
import styles from "styles/components/questionAnswersModal.module.scss";
import { QUESTION_TYPES, SORTING_OPTIONS } from "utils/constants";
import { smoothScroll } from "utils/utils";
import AnswerContent from "./answerContent";
import Clarifications from "./clarifications";
const QuestionAnswersModal = ({
  question,
  isOpen = false,
  close = () => {},
  users,
  onlyWithCommentDefaultValue = false,
  isAnonymous = false,
  refreshAnswers = () => {},
  defaultOpen,
}) => {
  const text = question?.text;
  const answers = question?.results?.answers.map((a, i) => ({ ...a, i }));
  const numberComments = (answers || []).reduce(
    (acc, el) => (el.comment && el.comment.length > 0 ? acc + 1 : acc),
    0
  );
  const [onlyWithComment, setOnlyWithComment] = useState(false);
  const onChangeOnlyComments = (e) => {
    setOnlyWithComment(e.target.checked);
  };

  const [sorting, setSorting] = useState(SORTING_OPTIONS.MOST_RECENT.value);

  const [scrollDone, setScrollDone] = useState(false);
  const handleChangeSort = (param) => {
    setSorting(param);
  };

  useEffect(() => {
    setOnlyWithComment(onlyWithCommentDefaultValue);
  }, [onlyWithCommentDefaultValue]);

  useEffect(() => {
    if (defaultOpen && defaultOpen.length > 0) {
      setTimeout(() => {
        if (!scrollDone) {
          smoothScroll(`#answer_${defaultOpen}_thread`);
          setScrollDone(true);
        }
      }, 500);
    }
  }, [defaultOpen]);

  const nbIncomplete = (
    isAnonymous
      ? answers?.filter((a) =>
          onlyWithComment ? a.comment && a.comment.length > 0 : true
        )
      : Object.values(users || {})
  )?.reduce((acc, el) => (el.incomplete ? acc + 1 : acc), 0);

  return (
    <Modal
      width={650}
      onCancel={() => {
        setOnlyWithComment(false);
        close();
      }}
      open={isOpen}
      title={`${text} (${answers?.length} ${pluralize(
        "answer",
        answers?.length
      )})`}
      footer={[
        <Button
          key={"close"}
          icon={<CloseOutlined />}
          type="primary"
          block
          onClick={() => {
            close();
          }}
        >
          Close
        </Button>,
      ]}
    >
      {question?.type === QUESTION_TYPES.OPEN_ENDED ? (
        <br />
      ) : (
        <div className={styles.actions}>
          <Checkbox
            disabled={numberComments <= 0}
            checked={onlyWithComment}
            onChange={onChangeOnlyComments}
          >
            With comment ({numberComments})
          </Checkbox>
          <div className={styles.actionsRight}>
            <Select
              disabled={answers?.length <= 0}
              style={{ width: 170 }}
              onChange={handleChangeSort}
              options={Object.values(SORTING_OPTIONS)}
              value={sorting}
            />
          </div>
        </div>
      )}
      {nbIncomplete > 0 ? (
        <Tag
          bordered={false}
          color={"grey"}
          style={{
            backgroundColor: "#ffa5002e",
            color: "#bb7900",
            fontSize: "16px",
            textAlign: "center",
            marginBottom: 22,
            cursor: "pointer",
            width: "100%",
            padding: "4px 8px",
          }}
        >
          <ExclamationCircleFilled style={{ color: "orange" }} />
          &nbsp;&nbsp;
          {`${nbIncomplete} incomplete ${
            nbIncomplete > 1 ? "responses are" : "response is"
          } listed`}
        </Tag>
      ) : (
        <></>
      )}
      {answers
        ?.filter((a) =>
          onlyWithComment ? a.comment && a.comment.length > 0 : true
        )
        .filter((a) => a.content || a.comment)
        .sort(SORTING_OPTIONS[sorting].sortFunction)
        .map((a, i) => {
          const user = a.userId ? users[a.userId] : null;
          const isLast = i === answers.length - 1;
          return (
            <div
              className={`${styles.answer}`}
              key={`${i}_answer_${a.content || ""}${a.comment || ""}${
                a.userId || ""
              }`}
              id={`answer_${a.responseId}`}
            >
              <div className={styles.answerInner}>
                <div className={styles.answerContent}>
                  <div className={styles.userWrapper}>
                    {user ? (
                      <div className={styles.userElement}>
                        <div className={styles.avatarWrapper}>
                          <Avatar
                            size={48}
                            src={user.profilePicture}
                            className={styles.avatar}
                          />
                          <div className={styles.avatarStatus}>
                            {user.incomplete ? (
                              <ExclamationCircleFilled
                                style={{ color: "orange" }}
                                className={styles.statusIcon}
                              />
                            ) : (
                              <></>
                            )}
                          </div>
                        </div>
                        <div className={styles.usernameWrapper}>
                          <div className={styles.username}>{user.name}</div>
                          <div className={styles.date}>
                            {user.incomplete
                              ? `Last answered at ${moment(
                                  user.lastAnsweredAt
                                ).format("L - HH:mm")}`
                              : `Submited on ${moment(user.submittedAt).format(
                                  "L - HH:mm"
                                )}`}
                          </div>
                        </div>
                      </div>
                    ) : (
                      <div
                        className={styles.usernameWrapper}
                        style={{ marginLeft: 0, marginBottom: 8 }}
                      >
                        <div className={styles.username}>
                          User anonymous
                          {a.incomplete ? (
                            <ExclamationCircleFilled
                              style={{ color: "orange", marginLeft: 4 }}
                              className={styles.statusIcon}
                            />
                          ) : (
                            <></>
                          )}
                        </div>
                        <div className={styles.date}>
                          {a.incomplete
                            ? `Last answered at ${moment(a.date).format(
                                "L - HH:mm"
                              )}`
                            : `Submited on ${moment(a.date).format(
                                "L - HH:mm"
                              )}`}
                        </div>
                      </div>
                    )}
                  </div>
                  <AnswerContent answer={a} question={question} />
                </div>
                {isOpen ? (
                  <Clarifications
                    answer={a}
                    refreshAnswers={refreshAnswers}
                    question={question}
                    incomplete={a.incomplete}
                    users={users}
                    isAnonymous={isAnonymous}
                  />
                ) : (
                  <></>
                )}
              </div>

              {!isLast && <Divider style={{ margin: 0, marginBottom: 24 }} />}
            </div>
          );
        })}
    </Modal>
  );
};

export default QuestionAnswersModal;
