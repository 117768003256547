import { FormOutlined, SendOutlined } from "@ant-design/icons";
import { Avatar, Button, Input, message } from "antd";
import moment from "moment";
import React, { useState } from "react";
import styles from "styles/components/clarifications.module.scss";
import { askCkarifications } from "utils/api";
import { THREAD_TYPE } from "utils/constants";
import { getTagFromType } from "utils/utils";
const { TextArea } = Input;
const Clarifications = ({
  answer,
  question,
  refreshAnswers,
  incomplete = false,
  users,
  isAnonymous = false,
}) => {
  const [askClarificationsResponseId, setAskClarificationsResponseId] =
    useState(null);
  const [clarificationContent, setClarificationContent] = useState(null);
  const [clarificationLoading, setClarificationLoading] = useState(false);

  const askClarifications = (answer) => {
    // show Input
    setAskClarificationsResponseId(answer.responseId);
  };

  const sendClarification = () => {
    setClarificationLoading(true);
    askCkarifications({
      responseId: askClarificationsResponseId,
      questionId: question.id,
      text: clarificationContent,
    })
      .then(() => {
        refreshAnswers();
        setClarificationContent(null);
        setAskClarificationsResponseId(null);
        message.success(
          "Clarification request sent, you'll receive a message on Slack when a response is provided."
        );
      })
      .catch((e) => {
        console.error(e);
        message.error("Error while send clarification, try again");
      })
      .finally(() => {
        setClarificationLoading(false);
      });
  };

  return (
    <>
      {answer.messages && answer.messages.length > 0 && (
        <div
          className={styles.threadWrapper}
          id={`answer_${answer.responseId}_thread`}
        >
          <h4>Conversation</h4>
          {answer.messages.map((msg) => {
            const user = msg?.userId ? users[msg.userId] : null;
            return (
              <>
                <div
                  key={msg.date}
                  className={`${styles.threadElement} ${
                    msg.type === THREAD_TYPE.QUESTION
                      ? styles.threadQuestion
                      : styles.threadAnswer
                  }`}
                >
                  <div className={styles.threadContainer}>
                    {user && (
                      <div className={styles.threadUsernameWrapper}>
                        <Avatar size={20} src={user?.profilePicture} />
                        <div className={styles.threadUsername}>{user.name}</div>
                      </div>
                    )}
                    <div className={styles.threadInner}>
                      <div className={styles.threadInnerTop}>
                        <div className={styles.threadTag}>
                          {getTagFromType(msg.type)}
                        </div>
                        <div className={styles.threadElementDate}>
                          {moment(msg.date).format("Do MMM YYYY - HH:mm")}
                        </div>
                      </div>

                      <div>{msg.text}</div>
                    </div>
                  </div>
                </div>
              </>
            );
          })}
        </div>
      )}
      <div style={{ position: "relative" }}>
        {askClarificationsResponseId === answer.responseId ? (
          <div style={{ marginTop: 12 }}>
            <TextArea
              rows={2}
              placeholder="Enter a your clarification request"
              value={clarificationContent}
              onChange={(e) => {
                setClarificationContent(e.target.value);
              }}
              disabled={clarificationLoading}
            />
            <div className={styles.clarificationCTAs}>
              <div className={styles.clarificationHint}>
                {isAnonymous ? (
                  <>
                    The conversation will stay anonymous, you'll be notified in
                    Slack when a new answer is provided
                  </>
                ) : (
                  <>You'll be notified in Slack when a new answer is provided</>
                )}
              </div>
              <Button
                disabled={
                  !clarificationContent || clarificationContent.length === 0
                }
                onClick={sendClarification}
                type="primary"
                loading={clarificationLoading}
                icon={<SendOutlined />}
              >
                Send clarification request
              </Button>
            </div>
          </div>
        ) : (
          <>
            {!incomplete &&
              (!answer.messages ||
                answer.messages.length === 0 ||
                answer.messages[answer.messages.length - 1].type ===
                  THREAD_TYPE.ANSWER) && (
                <div className={styles.linkAskClarification}>
                  <Button
                    onClick={() => {
                      askClarifications(answer);
                    }}
                    icon={<FormOutlined />}
                    type="primary"
                    ghost
                    size="small"
                    style={{
                      color: "#a9a9a9",
                      borderColor: "#d3d3d3",
                    }}
                  >
                    Ask Clarification
                  </Button>
                </div>
              )}
          </>
        )}
      </div>
    </>
  );
};

export default Clarifications;
