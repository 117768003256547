/* eslint-disable react-hooks/exhaustive-deps */
import { Alert, Button } from "antd";
import React, { useEffect, useState } from "react";

const TipEvolutionGraph = ({ keyStorage, content }) => {
  const [forcedClosed, setForcedClosed] = useState(true);
  const close = () => {
    setForcedClosed(true);
    localStorage.setItem(keyStorage, true);
  };
  useEffect(() => {
    const alreadyClosed = localStorage.getItem(keyStorage);
    if (!alreadyClosed) setForcedClosed(false);
  }, []);
  return forcedClosed ? (
    <></>
  ) : (
    <Alert
      style={{ marginTop: "8px", marginBottom: "8px" }}
      message={
        <>
          <b style={{ color: "#356fa5" }}>TIP</b>
          <span style={{ marginLeft: "12px", color: "#356fa5" }}>
            {content}
          </span>
        </>
      }
      type="info"
      showIcon
      action={
        <Button size="small" type="link" onClick={close}>
          Close tip
        </Button>
      }
    />
  );
};
export default TipEvolutionGraph;
